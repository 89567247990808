<template>
  <div class="container">
    <TheHeader />
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 col-lg-6 col-xl-4">
        <LoginForm />
      </div>
    </div>
    <p class="text-center mt-4">Want your own publication? <strong><router-link :to="{ name: 'register' }">Start a new publication</router-link></strong></p>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader'
import LoginForm from '@/components/Login/LoginForm'

export default {
  components: {
    TheHeader,
    LoginForm,
  },
}
</script>
